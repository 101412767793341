import jsonData from "./export.json"

const config = {
  apiGatewayEndpoint: jsonData.apiGatewayEndpoint,
  identityPoolId: jsonData.identityPoolId,
  userPoolId: jsonData.userPoolId,
  userPoolWebClientId: jsonData.userPoolWebClientId,
  region: "us-west-2",
  oauth: {
    domain: `dctx-${jsonData.userPoolWebClientId}.auth.us-west-2.amazoncognito.com`,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: `${window.location.origin}/get_dc`,
    redirectSignOut: `${window.location.origin}`,
    responseType: 'code',
    idpIdentifier: 'IdpIdentifier',
  }
}

export default config
