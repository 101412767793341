import React, { Component, useEffect, useState } from "react";
import { getJwtToken } from "../cognito-auth/cognito-auth";
import cognitoAuthConfig from "../cognito-auth/cognito-auth.config";
import { Context, ContextData } from "../models";
import { Autocomplete } from "./Combobox";

const DepCon: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [contextList, setContextList] = useState<Context[]>([]);
  const [contextData, setContextData] = useState<ContextData[]>([]);
  const [selectedContext, setSelectedContext] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [referrer, setReferrer] = useState(document.referrer);
  const [expandedItems, setExpandedItems] = useState(new Set<string>());
  const [query, setQuery] = useState("");
  const [filteredContextData, setFilteredContextData] = useState<ContextData[]>(
    []
  );

  const contexts: Context[] = require("../mocks/contexts.json");
  const data = require("../mocks/data.json");

  async function fetchMock() {
    setIsLoading(true);
    setTimeout(() => {
      if (selectedContext) {
        setIsLoading(false);
        setContextData(data[selectedContext]);
        setFilteredContextData(data[selectedContext]);
      } else {
        setIsLoading(false);
        setContextList(contexts);
      }
    }, Math.random() * (400 - 100) + 100);
  }

  async function fetchContextList() {
    if (window.location.host.includes("localhost")) {
      fetchMock();
    } else {
      try {
        setIsLoading(true);
        const token = await getJwtToken();
        const response = await fetch(cognitoAuthConfig.apiGatewayEndpoint, {
          method: "POST",
          body: JSON.stringify({}),
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let responseJson = await response.json();
        setIsLoading(false);
        setContextList(responseJson);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  }

  async function fetchContextData() {
    if (window.location.host.includes("localhost")) {
      fetchMock();
    } else {
      try {
        setIsLoading(true);
        const token = await getJwtToken();
        const response = await fetch(cognitoAuthConfig.apiGatewayEndpoint, {
          method: "POST",
          body: JSON.stringify({
            DeploymentContext: selectedContext,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let responseJson = await response.json();
        setIsLoading(false);
        setContextData(responseJson);
        setFilteredContextData(responseJson);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  }

  const toggleAdditionalInfo = (id: string) => {
    console.log(id);
    const newExpandedItems = new Set(expandedItems);
    if (expandedItems.has(id)) {
      newExpandedItems.delete(id);
      setExpandedItems(newExpandedItems);
    } else {
      newExpandedItems.add(id);
      setExpandedItems(newExpandedItems);
    }
    console.log(expandedItems);
  };

  const collapseAll = () => {
    const newExpandedItems = new Set<string>();
    setExpandedItems(newExpandedItems);
  };

  const expandAll = () => {
    const newExpandedItems = new Set(expandedItems);
    for (let item of filteredContextData) {
      newExpandedItems.add(item.ProjectId);
      setExpandedItems(newExpandedItems);
    }
  };

  useEffect(() => {
    fetchContextList();
  }, []);

  useEffect(
    function filtercontextData() {
      if (query === "") {
        setFilteredContextData(contextData);
      } else {
        const newList = contextData?.filter((item: ContextData) =>
          Object.keys(item.Consuming).some((key) => key.includes(query))
        );
        setFilteredContextData(newList);
      }
    },
    [query, contextData]
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    collapseAll();
    setQuery("");
    fetchContextData();
  };

  return (
    <div>
      <div
        className={`flex flex-wrap items-end mx-4 my-4 border-0 border-b border-solid py-2`}
      >
        <img
          className={`object-scale-down w-1/4 max-w-xs`}
          src={"./ng-logo.png"}
          alt="Navex Global Logo"
        />
        <h1 className={`font-sans text-3xl font-medium`}>Deployment Context</h1>
      </div>
      <form
        className="button mx-12 flex justify-center pt-16 items-center"
        onSubmit={handleSubmit}
      >
        <Autocomplete
          value={selectedContext}
          onChange={setSelectedContext}
          placeholder="Select Deployment Context..."
          data={
            contextList?.map((context: Context) => context.DeploymentContext) ||
            []
          }
          class="w-72 mr-3"
        />
        <div id="submitButton">
          <button className="btn-blue" type="submit">
            Submit
          </button>
        </div>
      </form>
      {isLoading && (
        <div className="text-2xl animate-pulse mx-8 my-4 border-0 py-2">
          <h1>Loading...</h1>
        </div>
      )}
      {contextData.length > 0 && !isLoading && (
        <div className="w-full p-8">
          <div className="py-4 flex justify-between items-center">
            <div>
              <label className="sr-only">Search Consumers</label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5"
                  placeholder="Filter by consumed..."
                  onInput={(e) =>
                    setQuery((e.target as HTMLInputElement).value)
                  }
                />
              </div>
            </div>
            <div>
              <button className="btn-red mr-3" onClick={collapseAll}>
                Collapse all
              </button>
              <button className="btn-blue" onClick={expandAll}>
                Expand all
              </button>
            </div>
          </div>
          <table className="w-full p-8 text-sm text-left text-gray-500 rounded-lg overflow-hidden ring-1 ring-black ring-opacity-20">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 w-5/12">
                  Project Name
                </th>
                <th scope="col" className="px-6 py-3 w-5/12">
                  Project ID
                </th>
                <th scope="col" className=" px-6 py-3 w-2/12"></th>
              </tr>
            </thead>
            {filteredContextData.map((item: ContextData, id: number) => (
              <tbody key={id}>
                <tr
                  className={
                    expandedItems.has(item.ProjectId)
                      ? "bg-white"
                      : "bg-white border-b"
                  }
                >
                  <th className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap">
                    {item.ProjectName ? item.ProjectName : ""}
                  </th>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {item.ProjectId ? item.ProjectId : ""}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-end">
                    <button
                      value="Expand"
                      className="font-medium text-blue-600 hover:underline"
                      onClick={() => toggleAdditionalInfo(item.ProjectId)}
                    >
                      {expandedItems.has(item.ProjectId)
                        ? "Collapse"
                        : "Expand"}
                    </button>
                  </td>
                </tr>
                <tr
                  className={
                    expandedItems.has(item.ProjectId)
                      ? "border-b"
                      : "hidden bg-gray-50"
                  }
                >
                  <td className="px-6 text-start align-top bg-gray-50">
                    {item.Artifacts && (
                      <>
                        <div className="font-medium py-2 text-xs text-gray-700 uppercase">
                          Artifacts:{" "}
                        </div>
                        {Object.keys(item.Artifacts).map((val, key) => (
                          <div className="font-medium text-s" key={key}>
                            <div
                              className="font-medium text-gray-900"
                              id="artifact_key"
                            >
                              {val}:
                            </div>{" "}
                            <div className="pl-2 font-light text-gray-900">
                              {item.Artifacts[val]}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </td>
                  <td className="px-6 align-top bg-gray-50">
                    {item.Consuming && (
                      <>
                        <div className="font-medium py-2 text-xs text-gray-700 uppercase">
                          Consuming:{" "}
                        </div>
                        {Object.keys(item.Consuming).map((val, key) => (
                          <div className="text-gray-900" key={key}>
                            <div className="font-medium text-s">{val}:</div>
                            {Object.keys(item.Consuming[val]).map(
                              (value, key) => (
                                <div
                                  className="pl-2 font-light text-gray-900"
                                  key={key}
                                >
                                  {value}: {item.Consuming[val][value]}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </td>
                  <td className="px-6 align-top bg-gray-50">
                    {item.DeployInProgress && (
                      <div className="text-xs py-2 text-gray-700 uppercase bg-gray-50">
                        Deploy in Progress{" "}
                        <div className="px-6 py-4 font-medium text-gray-900">
                          {item.DeployInProgress}
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
    </div>
  );
};
export default DepCon;
