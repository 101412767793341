import React, { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";

interface IProps {
  data: string[];
  class?: string;
  onChange?: any;
  value?: string;
  placeholder?: string;
}

export const Autocomplete: React.FC<IProps> = (props) => {
  const [query, setQuery] = useState("");

  const filteredData =
    query === ""
      ? props.data
      : props.data.filter((item) =>
          item
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className={`${props.class}`}>
      <Combobox value={props.value} onChange={props.onChange}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 sm:text-sm">
            <Combobox.Button as="div" className="flex items-center ">
              <Combobox.Input
                className="w-full py-2 pl-3 pr-10 text-sm leading-5 focus:ring-0 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                placeholder={props.placeholder}
                displayValue={(person: any) => person}
                onChange={(event) => setQuery(event.target.value)}
                autoComplete="off"
              />
              <SelectorIcon
                className="h-5 w-5 text-gray-400 cursor-pointer absolute right-0"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none sm:text-sm">
              {filteredData?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData?.map((person) => (
                  <Combobox.Option
                    key={person}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-navex-primary text-white" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {person}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          ></span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
