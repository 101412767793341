import React, { Component } from "react";
import Login from "./components/Login";
import DepCon from "./components/DepCon";
import { initiateLogin } from './cognito-auth/cognito-auth'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

class App extends Component<{}, {isLoading: boolean}> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

componentDidMount = () => {
  if (window.location.host.includes('localhost')) {
    this.setState({
      isLoading: false,
    })
  } else {
    initiateLogin(this.renderSuccess)
  }
}

renderSuccess = () => {
  this.setState({
    isLoading: false
  })
}

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/get_dc" />} />
          <Route exact path="/get_dc" component={this.state.isLoading ? Login : DepCon } />
        </Switch>
      </Router>
    );
  }
}

export default App;
