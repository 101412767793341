import React from 'react';

export default class LoginPage extends React.Component {
  render() {
    return (
      <div className="text-2xl animate-pulse mx-4 my-4 border-0 py-2">
        <h1>
          Loading...
        </h1>
      </div>
    )
  }
}
