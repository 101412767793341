import Auth from '@aws-amplify/auth'
import { MemoryStorage, Hub } from '@aws-amplify/core'
import cognitoAuthConfig from './cognito-auth.config'


const loadAmplifyAuthConfig = () => {
  const amplifyAuthConfig = {
    storage: MemoryStorage,
    ...cognitoAuthConfig,
  }
  return amplifyAuthConfig
}

const configureAuth = () => {
  return Auth.configure(loadAmplifyAuthConfig())
}

export const initiateLogin = async (callback: Function) => {
  configureAuth()
  try {
    const user = await Auth.currentAuthenticatedUser()
    completeLogin(callback, user)
  }
  catch (err) {
    setRedirectAfterLoginUrl()
    const signInUrl = buildSignInUrl()
    window.location.assign(signInUrl)
  }
}

const buildSignInUrl = () => {
  const { userPoolWebClientId, oauth: {
    domain,
    redirectSignIn,
    scope,
    idpIdentifier,
    responseType,
  } } = cognitoAuthConfig

  // double encode to preserve URL hash
  const redirect = encodeURIComponent(encodeURIComponent(window.location.href))
  const signInUrl = `https://${domain}/oauth2/authorize` +
    `?idp_identifier=${idpIdentifier}` +
    `&response_type=${responseType}` +
    `&redirect_uri=${redirectSignIn}` +
    `&client_id=${userPoolWebClientId}` +
    `&scope=${scope.join('+')}` +
    `&state=${encodeURIComponent('clientkey=dep-con-ui&redirect=')}${redirect}`

  return signInUrl
}

export const routeEquals = (value: string) => {
    return window.location.pathname === value
}

export const processLogin = (callback: Function) => {
  configureAuth()
  Hub.listen('auth', {
    onHubCapsule: capsule => {
      if (capsule.payload.event !== 'cognitoHostedUI') return
      completeLogin(callback)
    }
  })
}

const completeLogin = (callback: Function, user?: any) => {
  if (!user) {
    const redirect = getRedirectAfterLoginUrl()
    window.history.replaceState({}, '', redirect || '')
  }
  exposeAmplifyAuth()
  callback()
}

const exposeAmplifyAuth = () => {
  // @ts-ignore
  window.Amplify = {
    Auth: Auth,
  }
}

const redirectStorageKey = `RedirectAfterLogin`

const getRedirectAfterLoginUrl = () => {
  let redirect: string | 0 | null = window.localStorage.getItem(redirectStorageKey)
  if (!redirect) {
    window.localStorage.removeItem(redirectStorageKey)
  }
  else {
    const stateParameter = getUrlSearchParam(window.location.search, 'state')
    redirect = getUrlSearchParam(stateParameter || '', 'redirect')
  }
  return redirect
}

const setRedirectAfterLoginUrl = () => {
  window.localStorage.setItem(redirectStorageKey, window.location.href)
}

const getUrlSearchParam = (searchString: string, paramName: string) => {
  var results = new RegExp('[?&]' + paramName + '=([^&#]*)').exec(searchString)
  if (results == null) {
    return null
  }
  else {
    return decodeURIComponent(results[1]) || 0
  }
}

export const getJwtToken = async (callback?: any) => {
  const user = await Auth.currentAuthenticatedUser()
  const token = user.signInUserSession.idToken.jwtToken
  return token
}